import React from 'react'
import Layout from '../components/Layout/Layout'
import SEO from '../components/Core/seo'

const LinksPage = () => {
  return (
    <Layout>
      <SEO title="Links" />
      <div
        className="app"
        style={{ height: '100vh', backgroundColor: '#F4F4FC' }}
      >
        <section style={{ paddingTop: '160px' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-7">
                <div className="card panel-card">
                  <div className="card-body table-responsive">
                    <table className="table">
                      <thead>
                        <th>Platform</th>
                        <th>Test</th>
                        <th>Live</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Mobile (Pilot)</td>
                          <td>-</td>
                          <td>
                            <a
                              className="btn btn-primary btn-sm"
                              href="https://etc.weshelfie.com/apk/clearblue-pilot.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Pilot App
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Mobile</td>
                          <td>
                            <a
                              className="btn btn-primary btn-sm"
                              href="https://etc.weshelfie.com/apk/clearblue-test.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Test App
                            </a>
                          </td>
                          <td>
                            <a
                              className="btn btn-primary btn-sm"
                              href="https://etc.weshelfie.com/apk/clearblue-live.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Live App
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default LinksPage
